import React from 'react'
import { Box } from 'theme-ui'

const TimelineBlock = () => {
  return (
    <Box
      sx={{
        pr: '15px',
        position: 'relative',
        '&::after': {
          background: 'black',
          content: '""',
          height: '20px',
          position: 'absolute',
          top: '5px',
          right: '1px',
          width: '20px',
        },
      }}
    />
  )
}

export default TimelineBlock
