import React from 'react'
import { Grid, css } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const AboutImages = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "about/we_are_images" } }) {
          nodes {
            childImageSharp {
              fixed(width: 200, height: 264) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    `
  )

  return (
    <Grid
      sx={{
        display: ['flex', 'grid'],
        flexDirection: ['column'],
        gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr'],
        gridTemplateRows: ['1fr 1fr', '1fr 1fr 1fr 1fr'],
        gridTemplateAreas: [
          `". image-1 ." "image-2 image-1 image-3" "image-2 image-4 image-3" ". image-4 ."`,
        ],
        gap: ['50px 50px', null, null, null, '30px 30px'],
        ml: [null, null, '100px', null, '300px'],
      }}
    >
      {data.allFile.nodes.map((image, index: number) => {
        return (
          <Grid
            key={image.childImageSharp.fixed.src}
            sx={{ gridArea: `image-${index + 1}`, justifyContent: 'center' }}
          >
            <Img
              fixed={image.childImageSharp.fixed}
              key={image.childImageSharp.fixed.src}
              alt="Migration Harmony about collage"
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
