import React from 'react'
import { Box, Text } from 'theme-ui'
import { OurPlanTimeline } from './OurPlanTimeline'

const OurPlan = () => {
  return (
    <Box
      bg={['primary.two']}
      sx={{
        height: ['1150px', '700px', '500px'],
        mb: [0, '200px', null, null, '200px'],
      }}
    >
      <Text
        sx={{
          fontFamily: 'Futura Heavy',
          fontSize: 5,
          color: 'white',
          px: [3, 4],
          pt: 4,
          ml: [3, 4],
        }}
      >
        Our Plan
      </Text>
      <Text
        sx={{
          fontFamily: 'Futura Heavy',
          fontSize: 4,
          color: 'white',
          px: [3, 4],
          ml: [3, 4],
          mb: 3,
        }}
      >
        Three annual meetings. Dozens of publications. Hundreds of network
        members.
      </Text>
      <Box
        bg={['white']}
        sx={{
          mt: [2],
          mx: [3, 5],
          py: [3, 3, 0, 3],
          boxShadow: '0px 2px 5px 0px rgba(196,190,196,1);',
        }}
      >
        <OurPlanTimeline />
      </Box>
    </Box>
  )
}

export default OurPlan
