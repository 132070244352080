import React from 'react'
import { Box, Text, Flex } from 'theme-ui'
import { OurReasonImage } from './OurReasonImage'

const OurReason = () => {
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'column', 'row'],
        bg: 'lightBlue',
        p: [1, 4],
        py: [3, 5],
        mb: [3, null],
      }}
    >
      <OurReasonText />
      <OurReasonImage />
    </Flex>
  )
}

const OurReasonText = () => (
  <Box
    sx={{
      textAlign: ['center', 'left'],
      maxWidth: [null, null, '60vw'],
    }}
  >
    <Text variant="heading.lrg">Our Reason</Text>
    <Text variant="heading.sml">
      Sustainable, equitable, and science-driven decisions are best made when we
      work together.
    </Text>
    <Text
      variant="p"
      sx={{
        mt: '20px',
      }}
    >
      Urbanization, globalization, and the impacts of climate change are
      activating the simultaneous migrations of species, ecosystems,
      settlements, and cultures across Arctic coastlines in new and
      unpredictable ways. Arctic port cities are witnessing increased maritime
      traffic and inflow of migrant labor; rural villages are facing
      displacement from slow and sudden-onset disasters; sea ice melt and ocean
      warming are shifting marine species ranges; and terrestrial ecosystems in
      transition are upending Arctic food webs and introducing southern disease
      vectors.
    </Text>
    <Box />
    <Text
      variant="p"
      sx={{
        mt: '20px',
      }}
    >
      Each of these intersecting mobilities challenge the quality of life,
      sustainable development, and environmental health of the circumpolar
      north. A lack of integration across siloed research communities has
      hampered the expansion of knowledge to understand these interactions and
      provide decision makers and stakeholders with the necessary qualitative
      and quantitative data to make science-informed decisions. We want to
      change that.
    </Text>
  </Box>
)

export default OurReason
