import React from 'react'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { Hero } from '@components/About'

const About = () => (
  <Layout page="about">
    <SEO title="About" />
    <Hero />
  </Layout>
)

export default About
