import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { AboutImages } from './AboutImages'

const AboutInfo = () => {
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        p: [4],
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column'],
          pl: [null, null, null, null, '100px'],
        }}
      >
        <Text
          sx={{
            fontFamily: 'Futura Book Regular',
            fontSize: 5,
            textAlign: ['center', 'left'],
            mb: ['20px'],
          }}
        >
          We are
        </Text>
        <Text
          sx={{
            fontFamily: 'Futura Heavy',
            fontSize: [null, null, '30px', null, '40px'],
            textAlign: ['center', 'left'],
            mb: ['20px'],
          }}
        >
          A network of scholars, students, <Box />
          storytellers, and knowledge holders <Box />
          researching coastal Arctic migrations.
        </Text>
        <Text
          sx={{
            fontFamily: 'Futura Book Regular',
            fontSize: '18px',
            maxWidth: [null, '50vw', null, null, '35vw'],
            color: 'grey',
            textAlign: ['center', 'left'],
            mb: [4],
          }}
        >
          MIH-RCN is three-year international, cross-disciplinary network of
          Arctic migration researchers funded by a National Science Foundation
          grant to Georgetown University, under the direction of Dr. Victoria
          Herrmann, Assistant Research Professor and Managing Director of The
          Arctic Institute. We are traditional knowledge holders, natural
          scientists, engineers, students, humanities scholars, economists,
          social scientists, storytellers, engineers, health professionals,
          practitioners, and educators working on the many dimensions of Arctic
          migration.
        </Text>
      </Flex>
      <AboutImages />
    </Flex>
  )
}

export default AboutInfo
