import React from 'react'
import { Box, Text } from 'theme-ui'
import { OurMissionImage } from './OurMissionImage'
const MissionStatement = () => {
  return (
    <>
      <Box
        bg={['primary.two']}
        sx={{
          minHeight: [null, null, null, '100%', '75vmin'],
          height: ['250px', '350px', '500px', null],
          backgroundImage: [
            null,
            null,
            `
              linear-gradient(
                to right,
                white 0%,
                white 3%,
                #147CED 3%,
                #147CED 28%,
                white 28%,
                white 100%
              );
            `,
          ],
        }}
      >
        <Text
          variant="heading.lrg"
          sx={{
            color: 'white',
            p: 4,
            ml: [null, '40px', null, null, '100px'],
          }}
        >
          Our Mission
        </Text>
        <OurMissionImage />
      </Box>
      <Box
        sx={{
          position: 'relative',
          top: ['30px', '-100px', null, null, '-200px'],
          ml: ['5vw', null, '30vw'],
          my: [3],
          mb: [5, 5, 0],
        }}
      >
        <Text variant="heading.sml">
          To facilitate open communication, foster cross-disciplinary <Box />
          exchange, and build a community for migration researchers.
        </Text>
        <Text
          variant="p"
          sx={{
            mt: '20px',
          }}
        >
          From the hunter-gatherer populations who traversed across Siberia and
          into Beringia during the Late Pleistocene period to the 21st century
          Sami reindeer herders across Sápmi in Northern Europe, the Arctic has
          been on the move for millennia. And yet, the accelerated pace of
          ecological, societal, and climate changes today is introducing a new
          normal for the Arctic with new, at times unpredictable, patterns of
          movement for peoples, economies, and species.
        </Text>
        <Box />
        <Text
          variant="p"
          sx={{
            mt: '20px',
          }}
        >
          Our Research Coordination Network aims to facilitate open
          communication, foster cross-disciplinary exchange, and build new
          collaboration teams of scientists, stakeholders, and practitioners to
          investigate our organizing question: What are the ways in which the
          drivers and consequences of Arctic coastal migrations intersect and
          interact with one another, and what are the implications for society?
          Over the next three years, we’ll find the answers together.
        </Text>
      </Box>
    </>
  )
}

export default MissionStatement
