import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'theme-ui'

const Content = props => {
  const { children } = props

  return (
    <Flex
      sx={{
        lineHeight: 1,
        '&:last-child': {
          '.body-component': {
            '&::before': {
              display: 'none',
            },
          },
        },
      }}
    >
      {children}
    </Flex>
  )
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Content
