import React from 'react'
import { Box } from 'theme-ui'

const Timeline: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        padding: '10px 20px 20px',
        my: ['25px', null, null, null, '50px'],
      }}
    >
      {children}
    </Box>
  )
}

export default Timeline
