import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'theme-ui'

const ContentBody = props => {
  const { title, children } = props

  return (
    <Box
      className="body-component"
      sx={{
        fontSize: '0.9em',
        pb: '30px',
        pl: '15px',
        position: 'relative',
        '::before': {
          content: '""',
          backgroundColor: 'black',
          height: '100%',
          left: '-12px',
          position: 'absolute',
          top: '10px',
          width: '3px',
        },
      }}
    >
      <Text
        sx={{
          color: '#002a3a',
          fontWeight: 'bold',
          mb: '10px',
          fontSize: '18px',
          mt: 0,
          textAlign: 'left',
        }}
      >
        {title}
      </Text>
      {children}
    </Box>
  )
}

ContentBody.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ContentBody
