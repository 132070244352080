import React from 'react'
import { Box } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const OurMissionImage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { eq: "about/our_mission.png" }) {
          childImageSharp {
            fluid(maxHeight: 430, maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <Box px={[null, null, null, '50px', 105]} mx={4}>
      <Img
        fluid={data.heroImage.childImageSharp.fluid}
        key={data.heroImage.childImageSharp.fluid.src}
        alt="Migration Harmony hero collage"
      />
    </Box>
  )
}
