import React from 'react'
import { Box } from 'theme-ui'

import OurPlan from './OurPlan'
import WhatWeAreBuilding from './WhatWeAreBuilding'
import MissionStatement from './MissionStatement'
import OurReason from './OurReason'
import AboutInfo from './AboutInfo'
import OurPeople from './OurPeople'
import OurCommitment from './OurCommitment'

export const Hero = () => {
  return (
    <Box>
      <AboutInfo />
      <MissionStatement />
      <OurReason />
      <WhatWeAreBuilding />
      <OurPlan />
      <OurPeople />
      <OurCommitment />
    </Box>
  )
}

export default { Hero }
