import React from 'react'
import { Box, Text, Flex } from 'theme-ui'

const WhatWeAreBuilding = () => {
  return (
    <Box
      sx={{
        p: 5,
      }}
    >
      <Text
        variant="heading.lrg"
        sx={{
          textAlign: 'center',
        }}
      >
        What we are building
      </Text>
      <Text
        variant="heading.sml"
        sx={{
          textAlign: 'center',
        }}
      >
        An Arctic community to share ideas, support students, and collaborate on
        migration projects.
      </Text>
      <Flex
        sx={{
          flexDirection: ['column', 'row'],
          my: '20px',
        }}
      >
        <Text
          variant="p"
          sx={{
            textAlign: ['center', 'left'],
            mr: [0, '20px'],
          }}
        >
          MiH-RCN will advance knowledge on how the migrations of Arctic
          ecosystems, economies, peoples, and cultures interact with one
          another, and how the social, geohazard, and economic drivers and
          consequences of migrations intersect across different fields. We do
          this by (1) engaging local leaders, practitioners, and researchers
          from diverse disciplines in North America and Europe to bridge
          research divides and synthesize existing data; (2) linking ongoing
          major research initiatives to fill this research gap; and (3)
          implementing creative participatory methods to broaden participation
          in migration research.
        </Text>
        <Text
          sx={{
            textAlign: ['center', 'left'],
            mt: ['20px', 0],
          }}
          variant="p"
        >
          From high school students at Mt. Edgecumbe High School in Sitka to
          graduate students in SURGE Design and Development Launch Pilot from
          NSF INCLUDES, MiH-RCN is dedicated to elevating student involvement
          and voices. And by having collaborators in Canada, Iceland, Norway,
          Sweden, Finland, Russia, and Indigenous leaders, our networking is
          strengthening international collaboration. These collaborations can
          contribute regional solutions to societal problems that migration
          creates in public health, economic development, and cultural loss and
          damage.
        </Text>
      </Flex>
    </Box>
  )
}

export default WhatWeAreBuilding
