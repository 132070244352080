import React from 'react'
import { Box, Text, Flex } from 'theme-ui'
import { AboutImages } from './AboutImages'
import { Link } from 'gatsby'
import LongArrowRight from '../../../assets/long-arrow-right.svg'

const OurPeople = () => {
  return (
    <Box
      sx={{
        mt: ['100px'],
      }}
    >
      <Box
        sx={{
          p: 4,
        }}
      >
        <Text variant="heading.lrg">Our people</Text>
        <Text variant="p">
          To learn more about a team member, click their image.
        </Text>
      </Box>
      <Flex
        sx={{
          flexWrap: 'wrap',
        }}
      >
        <AboutImages />
      </Flex>
      <Text
        sx={{
          color: 'primary.two',
          fontFamily: 'Futura Bold',
          fontSize: [5],
          p: [4],
        }}
      >
        {' '}
        <Link
          to="/collaborators"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          View all collaborators{' '}
          <LongArrowRight
            style={{
              marginBottom: '-17px',
            }}
            width={50}
            height={50}
            fill={'#147CED'}
          />
        </Link>
      </Text>
    </Box>
  )
}

export default OurPeople
