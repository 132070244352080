/* eslint-disable no-tabs */
import React from 'react'
import { Box, Text } from 'theme-ui'

const Description: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        mt: '5px',
        textAlign: 'left',
      }}
    >
      <Text
        sx={{
          fontWeight: 300,
          maxWidth: ['75vw'],
        }}
      >
        {children}
      </Text>
    </Box>
  )
}

export default Description
