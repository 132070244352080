import React from 'react'
import { Container } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const OurReasonImage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { eq: "about/our_reason.png" }) {
          childImageSharp {
            fixed(height: 960, width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <Container
      sx={{
        height: ['550px', '500px'],
        width: ['500px'],
        mx: ['50px', 'auto'],
        mt: ['20px', '10px'],
      }}
    >
      <Img
        fixed={data.heroImage.childImageSharp.fixed}
        key={data.heroImage.childImageSharp.fixed.src}
        alt="About: our reason image"
        style={{
          height: '540px',
          width: '380px',
          borderRadius: '50px',
        }}
      />
    </Container>
  )
}
