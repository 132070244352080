import React from 'react'
import { Text } from 'theme-ui'
import {
  Timeline,
  Content,
  TimelineBlock,
  ContentBody,
  Description,
} from '../Timeline'

export const OurPlanTimeline = () => (
  <Timeline>
    <Content>
      <TimelineBlock />
      <ContentBody title="2020 | Seeing the system">
        <Description>
          <Text
            sx={{
              fontFamily: 'Futura Book Regular',
              fontSize: '16px',
              color: 'grey',
            }}
          >
            Year One will focus on sense-making – the process of understanding
            Arctic migrations, examining why their systemic interactions are
            important, and co-creating a network vision and values. We will
            invite participants, synthesize existing research projects, and
            initiate integrative dialogues across disciplinary and country
            borders. This will culminate in the annual meeting, where
            participants will be tasked to see the interactions,
            interdependencies, and intersections of Arctic coastal systems in
            movement through a participatory workshop.
          </Text>
        </Description>
      </ContentBody>
    </Content>
    <Content>
      <TimelineBlock />
      <ContentBody title="2021 | Design think & Simulate">
        <Description>
          <Text
            sx={{
              fontFamily: 'Futura Book Regular',
              fontSize: '16px',
              color: 'grey',
            }}
          >
            Year Two will focus on identifying gaps in collaborative migration
            research, map emerging patterns of movement, and share ideas that
            could transform the problem domain. We will continue to broaden and
            deepen the network, both inviting new participants and strengthening
            existing collaborations. This will culminate in the annual meeting,
            where participants will be tasked to co-develop an Arctic coastal
            migration scenario, which will then be simulated through together as
            a table-top exercise.
          </Text>
        </Description>
      </ContentBody>
    </Content>
    <Content>
      <TimelineBlock />
      <ContentBody title="2022 | Collaboratory incubation">
        <Description>
          <Text
            sx={{
              fontFamily: 'Futura Book Regular',
              fontSize: '16px',
              color: 'grey',
            }}
          >
            Year Three will focus on creating partnerships, facilitating new
            resource flows, and submitting collaborative grant applications to
            fill research gaps in Arctic coastal migrations. While continuing to
            invite new participants, our focus will shift to activities and
            strategic planning for long-term sustainability of the network’s
            synergies, relationships, and synthesis work. This will culminate in
            the annual meeting, where participants will be tasked to form
            integrated, multi-disciplinary research teams around the prioritized
            research topics and capacity building.
          </Text>
        </Description>
      </ContentBody>
    </Content>
  </Timeline>
)
