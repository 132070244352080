import React from 'react'
import { Box, Text, Container, Divider } from 'theme-ui'
import { Link } from 'gatsby'
import LongArrowRight from '../../../assets/long-arrow-right.svg'

const OurCommitment = () => {
  return (
    <Container
      sx={{
        flexDirection: ['column', 'column', 'row'],
        bg: 'lightBlue',
        p: [1, 4],
        py: [3, 5],
        textAlign: ['center'],
        justifyContent: 'center',
      }}
    >
      <Text sx={{ pb: '10px' }} variant="heading.lrg">
        Our Commitment
      </Text>
      <Text
        sx={{ maxWidth: ['400px', '700px', '1200px'], mx: 'auto' }}
        variant="heading.sml"
      >
        At its core, this network is about bringing people together. We strive
        to break down the barriers that divide us, facilitate open communication
        amongst our members, and build new collaborations in pursuit of more
        equitable, inclusive research teams. To do this, the leadership of the
        MiH-RCN commits to sustained, antiracist and anticolonial actions that:
      </Text>
      <Text
        variant="p"
        sx={{
          mt: '20px',
        }}
      >
        <Box
          sx={{ maxWidth: ['400px', '700px', '1200px'], mx: 'auto', py: [2] }}
        >
          {' '}
          1. Diversify our stories, research, and resources in order to amplify
          marginalized voices through our network events, publications, and
          initiatives.{' '}
        </Box>

        <Box
          sx={{ maxWidth: ['400px', '700px', '1200px'], mx: 'auto', py: [2] }}
        >
          2. Develop space, unique support, and responses resources for members
          from excluded or marginalized groups on our website and communication
          channels.{' '}
        </Box>

        <Box
          sx={{ maxWidth: ['400px', '700px', '1200px'], mx: 'auto', py: [2] }}
        >
          3. Devalue traditional hierarchies of authority by providing
          leadership opportunities for all members, especially early career
          participants, Indigenous participants, and participants from other
          marginalized groups.{' '}
        </Box>
      </Text>
      <Text
        sx={{ maxWidth: ['400px', '700px', '1200px'], mx: 'auto', pt: [4] }}
        variant="p"
      >
        Our skills, knowledge, and expertise are stronger when we collaborate in
        an open, just community. Our network leverages our participants from all
        walks of life to develop impactful research teams. Everyone has a
        valuable contribution to make, and we strive to incorporate principles
        of understanding, openness, and equity into all our work.
      </Text>
    </Container>
  )
}

export default OurCommitment
